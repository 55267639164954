<template>
  <div
    v-if="!isDropdown && user.name != 'None'"
    :class="selectedUser ? 'flex discordUser selected' : 'flex discordUser'"
  >
    <div>
      <img :src="avatar_url" alt="" class="avatar" />
    </div>
    <div>{{ user.name }}</div>
  </div>
</template>

<script>
import { toRefs, computed } from "vue";
import { useStore } from "vuex";
export default {
  props: ["userData", "dropdown"],
  setup(props) {
    const store = useStore();
    const { userData, dropdown } = toRefs(props);
    const selectedUser = computed(() => {
      const selected = store.getters["devposts/getSelectedUser"];
      return selected === userData.value.id;
    });

    /*     const avatarUrl = ref("../static/images/noavatar.png"); // Default fallback URL  
    const watchedAuthors = ref(store.getters["devposts/getAuthors"]);
    watch(
      () => store.getters["devposts/getAuthors"],
      (newAuthors) => {
        console.log("New Authors", newAuthors);
        watchedAuthors.value = newAuthors;
        const urlObj = newAuthors.find((item) => item.id === props.userData.id);
        avatarUrl.value = urlObj.avatar_url;
      }
    ); */
    const avatar_url = computed(() =>
      userData.value.avatar_url != "None"
        ? userData.value.avatar_url
        : "../static/images/noavatar.png"
    );
    const isDropdown = computed(() => dropdown.value);

    return { avatar_url, user: userData.value, selectedUser, isDropdown };
  },
};
</script>

<style scoped>
@media screen and (max-width: 999px) {
  .avatar {
    display: none;
  }
  .flex {
    display: flex;
    gap: 5px;
    align-items: center;
  }
  .discordUser {
    max-width: 1000px;
    cursor: pointer;
    margin: 0;
    padding: 0 4px 0 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.26);
    border: 1px solid #ccc;
    border-radius: 5px;
    line-height: 1.5;
    background-color: #1b1914;
  }
}

.selected {
  background-color: #383530;
  border: 1px solid #ccc;
}
@media screen and (min-width: 1000px) {
  .avatar {
    height: 32px;
    width: 32px;
    margin: 0;
    padding: 0;
  }
  .flex {
    display: flex;
    gap: 5px;
    align-items: center;
  }
  .discordUser {
    max-width: 500px;
    cursor: pointer;
    margin: 0;
    overflow: hidden;
    padding: 0 4px 0 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.26);
    border: 1px solid #ccc;
    border-radius: 5px;
    line-height: 0;
    background-color: #1b1914;
  }
  .selected {
    background-color: #383530;
    border: 1px solid #ccc;
  }
}
</style>
