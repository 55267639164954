<template>
  <footer :class="classes">
    <span class="hiroshige">(c) Feylinchen#1234</span>
  </footer>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
export default {
  components: {},
  setup() {
    const store = useStore();
    const selectedGame = computed(() => store.getters["devposts/getSelectedGame"]);
    const classes = computed(() => {
      if (selectedGame.value === null) {
        return "footer-none";
      } else {
        return `footer-${selectedGame.value}`;
      }
    });
    return { classes };
  },
};
</script>

<style scoped>
.footer-paxdei {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 2fr 1fr;
  grid-column-gap: 10px;
  align-items: center;
  background-color: rgb(220 221 212);
  color: rgb(34 31 25);
}

.footer-paxdei:after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  opacity: 1;
  bottom: 21px;
  height: 14px;
  background-size: 500px 100%;
  background-image: url("../static/images/scratch-paxdei.png");
  background-repeat: repeat-x;
  background-position-y: top;
}
.footer-corepunk {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 2fr 1fr;
  grid-column-gap: 10px;
  align-items: center;
  background-color: #b8ad93;
  color: rgb(34 31 25);
}

.footer-corepunk:after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  opacity: 1;
  bottom: 21px;
  height: 14px;
  background-size: 500px 100%;
  background-image: url("../static/images/scratch-corepunk.png");
  background-repeat: repeat-x;
  background-position-y: top;
}
</style>
