<template>
  <BaseContainer class="listPosts">
    <BaseContainer :border="true" :padding="false" style="padding: 8px">
      <BaseSearch
        :game="selectedGame"
        @search="setSearchTerm"
        @filterDate="forwardFilterDate"
      />
    </BaseContainer>
    <div v-if="isLoadingPosts">
      <BaseContainer :border="true">
        <div class="spinner">
          <q-spinner-hourglass color="#9a9c82" size="4em" />
        </div>
      </BaseContainer>
    </div>
    <div
      v-else-if="discordMessages && discordMessages.length > 0"
      class="scroll"
    >
      <MessageWrapper
        v-for="message of discordMessages"
        :key="extractMessageId(message.message_url)"
        :author="message.author"
        :clean_message="message.clean_message"
        :date="message.created_at"
        :url="message.message_url"
        :author_id="message.author_id"
        :avatar_url="message.avatar_url"
      ></MessageWrapper>
    </div>

    <div v-else>
      No User selected or no message found. Try to change the User, Search Term
      or the Date Filter.
    </div>
  </BaseContainer>
</template>

<script>
import BaseContainer from "../BaseComponents/BaseContainer.vue";
import MessageWrapper from "./MessageWrapper.vue";
import BaseSearch from "../BaseComponents/BaseSearch.vue";
import {
  toRefs,
  ref,
  computed,
  onBeforeUpdate,
  nextTick,
  watch,
  onMounted,
} from "vue";
import store from "@/store";
export default {
  components: { BaseContainer, MessageWrapper, BaseSearch },
  emits: ["search", "filterDate"],
  props: ["messages", "game"],
  setup(props, { emit }) {
    const { messages, game } = toRefs(props);
    console.log("Our messages", messages.value);
    const searchTerm = ref("");
    let messagesChanged = false;

    const extractMessageId = (url) => {
      const parts = url.split("/");
      return parts[parts.length - 1];
    };
    const setSearchTerm = (data) => {
      emit("search", data);
      searchTerm.value = data;
    };

    const forwardFilterDate = (data) => {
      emit("filterDate", data);
    };

    const isLoadingPosts = computed(
      () => store.getters["devposts/getIsLoadingPosts"]
    );
    watch(messages, () => {
      console.log("messages changed");
      messagesChanged = true;
    });
    onMounted(() => {
      setTimeout(() => {
        store.dispatch("devposts/setIsLoadingPosts", false);
      }, 1000);
      messagesChanged = false;
    });
    onBeforeUpdate(() => {
      if (messagesChanged) {
        setTimeout(() => {
          store.dispatch("devposts/setIsLoadingPosts", false);
        }, 1000);
        messagesChanged = false;
      }
    });
    nextTick(() => {
      console.log("tick tak");
    });
    return {
      discordMessages: messages,
      setSearchTerm,
      forwardFilterDate,
      selectedGame: game.value,
      isLoadingPosts,
      extractMessageId,
    };
  },
};
</script>

<style scoped>
.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
}
div::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
.scroll {
  max-height: calc(100vh - 196px);
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

.listPosts {
  max-width: 720px;
  flex-grow: 1;
}

@media screen and (max-width: 1000px) {
  .listPosts {
    max-width: 1000px;
  }
}
</style>
