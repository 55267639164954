export default {
  getData(state) {
    return state.devposts;
  },
  getSelectedUser(state) {
    return state.selectedUser;
  },
  getSelectedGame(state) {
    return state.selectedGame;
  },
  getPushToken(state) {
    return state.pushToken;
  },
  getPushEnabled(state) {
    return state.pushEnabled;
  },
  getSubscribedTopics(state) {
    return state.subscribedTopics;
  },
  getLoadingPush(state) {
    return state.loadingPush;
  },
  getDateFilter(state) {
    return { minDate: state.filterDateMin, maxDate: state.filterDateMax };
  },
  getIsLoadingPosts(state) {
    return state.isLoadingPosts;
  },
  getAuthors(state) {
    return state.authors;
  },
  getGames(state) {
    console.log("Get Game State:", state.games);
    return state.games;
  },
  getLastCheckedURL(state) {
    return state.lastCheckedURL;
  },
};
