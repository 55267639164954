<template>
  <!-- calendar button-->
  <div class="grid">
    <div>
      <q-btn icon="event" round color="primary">
        <q-popup-proxy
          @before-show="updateProxy"
          cover
          transition-show="scale"
          transition-hide="scale"
        >
          <q-date v-model="proxyDate" range dark>
            <div class="row items-center justify-end q-gutter-sm">
              <q-btn label="Cancel" color="primary" flat v-close-popup />
              <q-btn
                label="OK"
                color="primary"
                flat
                @click="save"
                v-close-popup
              />
            </div>
          </q-date>
        </q-popup-proxy>
        <q-tooltip style="background-color: #4e4c47" class="text-body2"
          >Filter by Date</q-tooltip
        >
      </q-btn>
    </div>
    <div>
      <q-btn
        v-if="token != null && !subscribed && pushEnabled"
        @click="subscribe"
        round
        color="primary"
        icon="campaign"
        :loading="isLoading"
      >
        <q-tooltip style="background-color: #4e4c47" class="text-body2"
          >Subscribe to {{ selectedGame }}</q-tooltip
        >
      </q-btn>
      <q-btn
        v-else-if="token != null && subscribed && pushEnabled"
        @click="unsubscribe"
        round
        color="secondary"
        icon="campaign"
        :loading="isLoading"
      >
        <q-tooltip style="background-color: #4e4c47" class="text-body2"
          >Unsubscribe from {{ selectedGame }}</q-tooltip
        >
      </q-btn>
      <q-btn
        v-else-if="token === null || (subscribed && !pushEnabled)"
        @click="askForPush"
        round
        color="deep-orange"
        icon="campaign"
        :loading="isLoading"
      >
        <q-tooltip style="background-color: #4e4c47" class="text-body2"
          >Enable Push Notifications</q-tooltip
        >
      </q-btn>
    </div>
    <!-- search bar-->
    <div>
      <form>
        <input
          type="search"
          @input="search"
          :value="searchTerm_ref"
          placeholder="Filter items"
        />
      </form>
    </div>
  </div>
</template>

<script>
import { toRefs, ref, computed } from "vue";
import { useStore } from "vuex";
import { getToken } from "firebase/messaging";
import { messaging } from "../../App.vue";
import { useQuasar } from "quasar";
export default {
  props: ["searchTerm", "game"],
  emits: ["search", "filterDate"],
  setup(props, { emit }) {
    const $q = useQuasar();
    const store = useStore();
    const { searchTerm, game } = toRefs(props);

    const isLoading = computed(() => {
      return store.getters["devposts/getLoadingPush"];
    });
    const pushEnabled = computed(() => {
      return store.getters["devposts/getPushEnabled"];
    });
    const dateFilter = computed(() => store.getters["devposts/getDateFilter"]);
    console.log("date Filter", dateFilter.value);
    const minDate = computed(() => dateFilter.value.minDate);
    const MaxDate = computed(() => dateFilter.value.maxDate);

    const date = ref({ from: minDate.value, to: MaxDate.value });
    const proxyDate = ref({ from: "2023/01/01", to: MaxDate.value });
    const save = () => {
      date.value = proxyDate.value;
      emit("filterDate", date.value);
    };

    const updateProxy = () => {
      proxyDate.value = date.value;
    };

    const search = (event) => {
      emit("search", event.target.value);
    };
    const token = computed(() => {
      return store.getters["devposts/getPushToken"];
    });

    const subscribed = computed(() => {
      const topics = store.getters["devposts/getSubscribedTopics"];
      if (topics) {
        return topics[game.value];
      }
      return false;
    });
    const subscribe = () => {
      const token = store.getters["devposts/getPushToken"];
      store.dispatch("devposts/subscribleToGame", { token, game });
    };
    const unsubscribe = () => {
      const token = store.getters["devposts/getPushToken"];
      console.log(`Our token is ${token}`);
      store.dispatch("devposts/unSubscribleToGame", { token, game });
    };

    const askForPush = () => {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          store.dispatch("devposts/setPushEnabled", true);
          store.dispatch("devposts/setLoadingPush", true);
          getToken(messaging, {
            vapidKey:
              "BC3T-Ek7h4jP4NrQBs4Sd-_mBcfRdeh2jjn_ck8HXY9FokfZTTFyTSNbgHhWlPJN9Cav8LJSWwm6s9ctbfjm5QM",
          })
            .then((currentToken) => {
              if (currentToken) {
                store.dispatch("devposts/addUserToSubs", currentToken);
                $q.notify({
                  message: "Push Enabled. Subscribe to the Game now",
                  position: "top",
                });
              } else {
                // Show permission request UI
                console.log(
                  "No registration token available. Request permission to generate one."
                );
                // ...
              }
            })
            .catch((err) => {
              console.log("An error occurred while retrieving token. ", err);
              console.log(err);
            });
        }
      });
    };

    return {
      save,
      updateProxy,
      search,
      searchTerm_ref: searchTerm.value,
      date,
      subscribe,
      subscribed,
      unsubscribe,
      token,
      proxyDate,
      pushEnabled,
      isLoading,
      selectedGame: game.value,
      askForPush,
    };
  },
};
</script>

<style scoped>
input {
  font: inherit;
  width: 100%;
  display: block;
  padding: 0.15rem;
  border: 1px solid #ccc;
}
.grid {
  display: grid;
  grid-template-columns: auto auto 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 0px;
  justify-content: center;
  align-items: center;
}

input:focus {
  outline: none;
  border-color: #00006b;
  background-color: #eeeeff;
}
</style>
