<template>
  <BaseContainer scrollable="true">
    <div
      v-if="discordUsers.length > 0 && width > 1000"
      class="discordContainer"
    >
      <DiscordUser
        v-for="user of discordUsers"
        :key="user"
        @click="setActiveUser(user.id)"
        :userData="user"
        :dropdown="false"
      >
      </DiscordUser>
    </div>
    <q-select
      v-else-if="discordUsers.length > 0"
      filled
      dark
      color="secondary"
      v-model="selectedUser"
      :options="discordUsers"
      option-value="id"
      option-label="name"
      label="Select Discord User"
    />
  </BaseContainer>
</template>

<script>
import store from "@/store";

import BaseContainer from "../BaseComponents/BaseContainer.vue";
import DiscordUser from "./DiscordUser.vue";
import {
  onUpdated,
  toRefs,
  ref,
  onMounted,
  onUnmounted,
  computed,
  watch,
} from "vue";
import { useRouter } from "vue-router";
export default {
  components: { BaseContainer, DiscordUser },
  props: ["authors", "site"],

  setup(props) {
    const selectedUser = ref(null);
    const onWidthChange = () => (windowWidth.value = window.innerWidth);
    let windowWidth = ref(window.innerWidth);
    onUnmounted(() => window.removeEventListener("resize", onWidthChange));
    const router = useRouter();
    const { authors, site } = toRefs(props);
    const setActiveUser = (author_id) => {
      store.dispatch("devposts/setActiveUser", author_id);
      router.replace(`/${site.value}/${author_id}`);
    };
    const updateData = ref({});
    onUpdated(() => {
      updateData.value = authors.value;
    });
    onMounted(() => {
      window.addEventListener("resize", onWidthChange);
      updateData.value = authors.value;
    });
    const width = computed(() => windowWidth.value);

    const discordUsers = computed(() => {
      const discordUsers = [];
      const author_item = {
        name: "Display all messages",
        avatar_url:
          "https://cdn.discordapp.com/avatars/126614909722165248/a_65b34add99ee822ffc5daa869aca2d4a.gif?size=128",
        id: "all",
      };
      discordUsers.push(author_item);
      for (const authorKey of Object.keys(authors.value)) {
        const author = authors.value[authorKey];
        discordUsers.push({
          name: author.username,
          id: author.guid,
          avatar_url: author.avatar_url,
        });
      }
      return discordUsers;
    });
    watch(selectedUser, (newValue) => {
      console.log(newValue.id);
      store.dispatch("devposts/setActiveUser", newValue.id);
      router.replace("/" + site.value + "/" + newValue.id);
    });
    return {
      discordUsers,
      setActiveUser,
      width,
      selectedUser,
    };
  },
};
</script>

<style scoped>
.custom_selected {
  background-color: rgb(34, 31, 25);
}

.discordContainer {
  margin-top: 0.5rem;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 5px;
}
</style>
