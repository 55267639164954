import Notify from 'quasar/src/plugins/Notify.js';;
import { saveData, getData } from "@/store/indexedDBUtils";

function titleCase(string) {
  return string[0].toUpperCase() + string.slice(1).toLowerCase();
}

const avatarUrlValid = async (url) => {
  if (url == "None") return "None";
  try {
    const result = await fetch(url, { method: "HEAD" });
    if (result.ok) {
      return url;
    } else {
      return "None";
    }
  } catch (error) {
    console.error("Avatar Offline, Fallback to Default");
  }
};

export default {
  async getGames({ commit }) {
    const response = await fetch(process.env.VUE_APP_DOMAIN + "/api/games");
    if (response.ok) {
      const responseJson = await response.json();
      commit("setGames", responseJson.games);
    }
  },
  async checkIfUpdateNeeded(store, { game_id, lastFetch }) {
    console.log("Check for update gameID", game_id);
    const lastDBUpdate = await fetch(
      `${process.env.VUE_APP_DOMAIN}/api/game/updates?game=${game_id}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    );

    if (lastDBUpdate.ok) {
      const responseJson = await lastDBUpdate.json();
      const lastUpdateDate = new Date(responseJson.data.lastUpdate);
      const lastFetchDate = new Date(lastFetch);
      console.log("Last Update Date is", lastUpdateDate);
      console.log("Last Fetch Date is", lastFetchDate);
      return lastUpdateDate.getTime() !== lastFetchDate.getTime();
    }
    return false;
  },

  async fetchAndUpdateData({ commit }, { game_id }) {
    const response = await fetch(
      `${process.env.VUE_APP_DOMAIN}/api/messages/game?game_id=${game_id}`
    );
    if (response.ok) {
      const responseJson = await response.json();
      commit("setData", responseJson.messages);
      console.log("Data fetched and stored locally");
      await saveData(game_id, responseJson.messages);
      localStorage.setItem(`lastFetch_${game_id}`, responseJson.lastUpdated);
    }
  },
  async getPosts({ commit, dispatch }, game_id) {
    console.log("ENV", process.env.VUE_APP_DOMAIN);
    await dispatch("getAuthorsByGame", game_id);
    const gameEnvironment = `VUE_APP_${game_id}_DATA`;
    const oldData = await getData(game_id);
    const lastFetch = localStorage.getItem(`lastFetch_${game_id}`);
    if (oldData && lastFetch) {
      console.log("Game before passing is.", game_id);
      const isUpdateNeeded = await dispatch("checkIfUpdateNeeded", {
        game_id,
        lastFetch,
      });
      if (isUpdateNeeded) {
        console.log("We need to fetch the latest data");
        await dispatch("fetchAndUpdateData", {
          gameEnvironment,
          game_id,
        });
      } else {
        console.log("No Update needed. Data is still up2Date");
        commit("setData", oldData);
      }
    } else {
      await dispatch("fetchAndUpdateData", {
        gameEnvironment,
        game_id,
      });
    }
  },

  async getAuthorsByGame({ dispatch }, game) {
    const response = await fetch(
      `${process.env.VUE_APP_DOMAIN}/api/authors?game_id=${game}`
    );
    if (response.ok) {
      const responseJson = await response.json();
      await dispatch("checkAvatarURLs", responseJson.authors);
      console.log("Authors fetched and stored locally", responseJson);
    }
  },

  async checkAvatarURLs(store, users) {
    console.log(users);
    const discordData = await Promise.all(
      users.map(async (user) => {
        const avatar_url = await avatarUrlValid(user.avatar_url);

        return {
          username: user.username, // Changed from user.author to user.username
          avatar_url: avatar_url, // This remains the same
          guid: user.guid, // Changed from user.author_id to user.guid
          game_id: user.game_id, // Optionally include game_id if needed elsewhere
        };
      })
    );
    console.log("checked Avatar URLs for validity");
    store.commit("setAuthors", discordData);
  },

  setActiveUser(store, id) {
    store.commit("setActiveUser", id);
  },
  setSelectedGame(store, game) {
    store.commit("setSelectedGame", game);
  },
  setPushToken(store, token) {
    store.commit("setPushToken", token);
    console.log("setPushToken");
    store.dispatch("getMyTopics", token);
  },
  setLoadingPush(store, status) {
    store.commit("setLoadingPush", status);
  },
  setIsLoadingPosts(store, status) {
    store.commit("setIsLoadingPosts", status);
  },
  setPushEnabled(store, status) {
    console.log("Setting Store");
    store.commit("setPushEnabled", status);
  },
  setAuthors(store, authors) {
    store.commit("setAuthors", authors);
  },

  setLastCheckedURL(store, url) {
    store.commit("lastCheckedURL", url);
  },
  async addUserToSubs(store, token) {
    store.dispatch("setLoadingPush", true);
    console.log("addUserToSubs");
    const request = {
      id: token,
    };
    const response = await fetch(
      "https://us-central1-paxdei-devtracker.cloudfunctions.net/addPushUser", // https://us-central1-paxdei-devtracker.cloudfunctions.net/addPushUser http://127.0.0.1:5001/paxdei-devtracker/us-central1/addPushUser
      {
        method: "PUT",
        body: JSON.stringify(request),
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    if (response.ok) {
      console.log(response.body);
      store.dispatch("setPushToken", token);
    }
  },
  async getMyTopics(store, token) {
    console.log("getMyTopics");
    const request = {
      id: token,
    };
    const response = await fetch(
      `https://us-central1-paxdei-devtracker.cloudfunctions.net/getPushUser`,
      {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.ok) {
      const body = await response.json();
      store.dispatch("setLoadingPush", false);
      store.dispatch("setSubscribedTopics", body);
    }
  },
  setSubscribedTopics(store, data) {
    store.commit("setSubscribedTopics", data);
  },
  async subscribleToGame(store, payload) {
    store.dispatch("setLoadingPush", true);
    console.log(`Subscrible to ${payload.game.value}`);
    console.log(payload.token);
    const gamename = titleCase(payload.game.value);
    const request = {
      id: payload.token,
    };
    const response = await fetch(
      "https://us-central1-paxdei-devtracker.cloudfunctions.net/subscribeTo" + // https://us-central1-paxdei-devtracker.cloudfunctions.net/subscribeTo
        gamename,
      {
        method: "PUT",
        body: JSON.stringify(request),
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    if (response.ok) {
      store.dispatch("setLoadingPush", false);
      Notify.create({
        message: `You have subscribed to ${gamename}`,
        position: "top",
      });
      const body = await response.json();
      store.dispatch("setSubscribedTopics", body);
    }
  },
  async unSubscribleToGame(store, payload) {
    store.dispatch("setLoadingPush", true);
    const gamename = titleCase(payload.game.value);
    const request = {
      id: payload.token,
    };
    const response = await fetch(
      "https://us-central1-paxdei-devtracker.cloudfunctions.net/unSubscribeTo" + // https://us-central1-paxdei-devtracker.cloudfunctions.net/unSubscribeTo
        gamename,
      {
        method: "DELETE",
        body: JSON.stringify(request),
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    if (response.ok) {
      store.dispatch("setLoadingPush", false);
      Notify.create({
        message: `You have unsubscribed from ${gamename}`,
        position: "top",
      });
      const body = await response.json();
      store.dispatch("setSubscribedTopics", body);
    }
  },
};
