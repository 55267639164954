<template>
  <div class="border baseContainer no-overflow">
    <div class="overlay">
      <div class="content">
        <div class="q-pa-md q-gutter-sm wrapper">
          <q-btn
            style="background: #b7b9a6; color: #575946"
            round
            color="secondary"
            icon="web"
            :href="'https://' + dataObj.url"
            target="_blank"
          >
            <q-tooltip style="background-color: #221f19" class="text-body2"
              >Open Message on Discord Web</q-tooltip
            >
          </q-btn>
          <q-btn
            style="background: #b7b9a6; color: #575946"
            round
            color="secondary"
            icon="discord"
            :href="'discord://' + dataObj.url"
            ><q-tooltip style="background-color: #221f19" class="text-body2"
              >Open Message on Discord Client</q-tooltip
            >
          </q-btn>
          <q-btn
            style="background: #b7b9a6; color: #575946"
            @click="copyMessage"
            round
            color="secondary"
            icon="content_copy"
            ><q-tooltip style="background-color: #221f19" class="text-body2"
              >Copy Message</q-tooltip
            >
          </q-btn>
        </div>
      </div>
    </div>
    <div class="grid">
      <!-- Image Container-->
      <div class="image grid-2-col">
        <img
          :src="avatar != 'None' ? avatar : '../static/images/noavatar.png'"
          alt=""
          class="avatar"
        />
        <div>
          <div class="badge">
            <q-badge
              outline
              style="color: #aeb09b"
              :label="'Posted on: ' + dataObj.date"
            />
          </div>
        </div>
      </div>
      <!-- message container-->
      <div>
        <div :class="dataObj.clean_message === '' ? 'bold padding' : 'padding'">
          <!-- this will stop try to display Embed things, like Images-->
          {{
            dataObj.clean_message === ""
              ? "Something Embed, please open Original Message"
              : dataObj.clean_message
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toRefs } from "vue";
import useQuasar from "quasar/src/composables/use-quasar.js";

export default {
  props: ["author", "clean_message", "date", "url", "author_id", "avatar_url"],
  components: {},
  setup(props) {
    const $q = useQuasar();
    const { author, clean_message, date, url, avatar_url } = toRefs(props);

    const belowTen = (number) => {
      if (number < 10) {
        return "0" + number;
      } else {
        return number;
      }
    };

    const copyMessage = () => {
      navigator.clipboard.writeText(author.value + " : " + clean_message.value);
      $q.notify({
        message: "Copied to Clipboard",
        color: "#b8ad93",
        position: "top",
      });
    };

    const createdTime = new Date(date.value);

    const timeZone = createdTime.getTimezoneOffset();
    const formatedTime =
      belowTen(createdTime.getHours()) +
      ":" +
      belowTen(createdTime.getMinutes());

    const formatedDate =
      createdTime.getFullYear() +
      "-" +
      belowTen(createdTime.getMonth() + 1) +
      "-" +
      belowTen(createdTime.getDate());

    const dataObj = {
      time: formatedTime,
      date: formatedDate,
      author: author.value,
      clean_message: clean_message.value,
      timezone: timeZone,
      url: url.value.replace("https://", ""),
    };

    return { avatar: avatar_url.value, dataObj, copyMessage };
  },
};
</script>

<style scoped>
.avatar {
  max-width: 40px;
  max-height: 40px;
}
.badge:first-child {
  margin-top: 2.5px;
  margin-bottom: 2.5px;
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 5px;
  align-items: center;
}
.date {
  font-size: smaller;
}
.author {
  font-size: smaller;
}
.grid-2-col {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 5px;
}
.image {
  line-height: 0;
}

.no-overflow {
  overflow: hidden;
}
.border {
  border: 1px solid #dcddd4;
  border-radius: 4px;
}
.padding {
  padding: 0 5px 5px 5px;
}
.baseContainer {
  margin: 0.5rem;
  flex: 1;
  position: relative;
  line-height: 1.5;
  background-color: #1b1914;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #4e4c47;
}

.baseContainer:hover .overlay {
  opacity: 1;
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
</style>
