import { createWebHistory, createRouter } from "vue-router";

import NotFound from "./pages/NotFound.vue";
import DevPosts from "./pages/devposts/DevPosts.vue";
import SelectGame from "./pages/SelectGame.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      component: SelectGame,
    },
    {
      path: "/:game/:game_id",
      component: DevPosts,
      props: true,
    },
    {
      path: "/:game/:game_id/:user_id",
      component: DevPosts,
      props: true,
    },
    { path: "/:notFound(.*)", component: NotFound },
  ],
});

export default router;
