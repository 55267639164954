<template>
  <div class="container">
    <div>
      <h3>Select your Game</h3>
      <div
        class="row justify-center q-ma-md q-gutter-sm"
        v-if="games && games.length > 0"
      >
        <q-btn
          v-for="game of games"
          outline
          rounded
          color="#9a9c82"
          :label="game.name"
          :to="`/${game.name.toLowerCase()}/${game.id}`"
          :key="game.id"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, computed } from "vue";
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore();

    onMounted(async () => {
      await store.dispatch("devposts/getGames");
      store.commit("devposts/setSelectedGame", null);
      store.commit("devposts/setData", []);
      store.commit("devposts/setAuthors", []);
    });

    const games = computed(() => store.getters["devposts/getGames"]);
    return { games };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  margin: 2rem;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  flex: 1;
  margin: space-around;
}
</style>
