<!-- eslint-disable no-undef -->
<!-- eslint-disable  -->
<template>
  <TheHeader />
  <router-view v-slot="slotProps">
    <transition name="route" mode="out-in">
      <component :is="slotProps.Component"></component>
    </transition>
  </router-view>
  <TheFooter />
</template>

<script>
import useQuasar from "quasar/src/composables/use-quasar.js";
import { onMounted, ref } from "vue";
import TheHeader from "./components/TheHeader.vue";
import TheFooter from "./components/TheFooter.vue";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBG5yX2LsomWFYGUyo0xX8wfko-0FNH4BI",
  authDomain: "paxdei-devtracker.firebaseapp.com",
  databaseURL:
    "https://paxdei-devtracker-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "paxdei-devtracker",
  storageBucket: "paxdei-devtracker.appspot.com",
  messagingSenderId: "896239086362",
  appId: "1:896239086362:web:0d3e208b77ab068b6edaa3",
  measurementId: "G-TG9N0VG92D",
};

// Initialize Firebase
initializeApp(firebaseConfig);

// Get registration token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.
export const messaging = getMessaging();

import { useStore } from "vuex";

export default {
  components: {
    TheHeader,
    TheFooter,
  },

  setup() {
    const store = useStore();
    const $q = useQuasar();

    onMessage(messaging, (payload) => {
      console.log("Message recieved.", payload);
      $q.notify({
        message: payload.notification.body,
        caption: payload.notification.title,
        color: "#b8ad93",
      });
    });

    onMounted(() => {
      if (Notification.permission === "granted") {
        store.dispatch("devposts/setPushEnabled", true);
        console.log("We can sent push");
        getToken(messaging, {
          vapidKey:
            "BC3T-Ek7h4jP4NrQBs4Sd-_mBcfRdeh2jjn_ck8HXY9FokfZTTFyTSNbgHhWlPJN9Cav8LJSWwm6s9ctbfjm5QM",
        })
          .then((currentToken) => {
            if (currentToken) {
              store.dispatch("devposts/addUserToSubs", currentToken);
            } else {
              // Show permission request UI
              console.log(
                "No registration token available. Request permission to generate one."
              );
              // ...
            }
          })
          .catch((err) => {
            console.log("An error occurred while retrieving token. ", err);
            console.log(err);
          });
      }
    });
    Date.prototype.addHours = function (h) {
      this.setTime(this.getTime() + h * 60 * 60 * 1000);
      return this;
    };
    Date.prototype.removeHours = function (h) {
      this.setTime(this.getTime() - h * 60 * 60 * 1000);
      return this;
    };
    return {
      leftDrawerOpen: ref(false),
    };
  },
};
</script>

<style>
* {
  box-sizing: border-box;
}
@font-face {
  font-family: Hiroshige;
  src: url("./hiro.otf") format("opentype");
}
@font-face {
  font-family: Sorrow1;
  src: url("./sorrow1.ttf") format("truetype");
}
* {
  box-sizing: border-box;
}
body,
html {
  background-color: rgb(34, 31, 25);
  color: #aeb09b;
  font-family: Arial, sans-serif !important;
}
body {
  margin: 0;
  /*  height: calc(100vh - 100px); */
}

.bg-style {
  background-color: #221f19;
}

.margin-top-40 {
  margin-top: 40px;
}

.hiroshige {
  font-family: Hiroshige;
}

.route-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}
main {
  display: flex;
  justify-content: space-around;
}
.route-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
.route-enter-active {
  transition: all 0.3s ease-out;
}
.route-leave-active {
  transition: all 0.3s ease-in;
}
.router-leave-from,
.router-enter-to {
  opacity: 1;
  transform: translateY(0);
}
</style>
