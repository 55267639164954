<template>
  <main v-if="Object.keys(authors).length > 0">
    <DiscordUsers :authors="authors" :site="url"></DiscordUsers>

    <ListPosts
      :game="selectedGame"
      :messages="messagePosts"
      @search="setSearchTerm"
      @filterDate="setDateFilter"
    ></ListPosts>
  </main>
  <main class="spinner" v-else>
    <q-spinner-hourglass color="#9a9c82" size="4em" />
  </main>
</template>

<script>
import { toRefs, computed, ref, watch, onMounted } from "vue";
import { useStore } from "vuex";
import DiscordUsers from "@/components/UserListComponents/DiscordUsers.vue";
import ListPosts from "@/components/ListMessagesComponents/ListPosts.vue";

export default {
  components: { DiscordUsers, ListPosts },
  props: ["user_id", "game", "game_id"],

  setup(props) {
    const store = useStore();
    const { user_id, game, game_id } = toRefs(props);

    onMounted(async () => {
      await store.dispatch("devposts/getPosts", game_id.value);
      store.dispatch("devposts/setSelectedGame", game.value);
    });

    if (user_id.value) {
      store.dispatch("devposts/setActiveUser", user_id.value);
    }
    const userSelected = computed(
      () => store.getters["devposts/getSelectedUser"]
    );
    const searchTerm = ref("");
    const setSearchTerm = (term) => {
      console.log("Serach Term from DevPosts");
      searchTerm.value = term;
    };
    const userData = computed(() => {
      return store.getters["devposts/getData"];
    });

    const authors = computed(() => {
      return store.getters["devposts/getAuthors"];
    });

    watch(game, (newGame, oldGame) => {
      console.log(newGame);
      console.log(oldGame);
    });

    const belowTen = (number) => {
      if (number < 10) {
        return "0" + number;
      } else {
        return number;
      }
    };

    const endDate = computed(() => {
      const today = new Date().addHours(24);
      const monthOffset = today.getMonth() + 1;
      const dayOffset = today.getDate();

      return (
        today.getFullYear() +
        "/" +
        belowTen(monthOffset) +
        "/" +
        belowTen(dayOffset)
      );
    });
    const startDate = computed(() => {
      const today = new Date();
      today.setDate(today.getDate() - 7);
      const monthOffset = today.getMonth() + 1;
      const dayOffset = today.getDate();
      const startDate =
        today.getFullYear() +
        "/" +
        belowTen(monthOffset) +
        "/" +
        belowTen(dayOffset);

      return startDate;
    });
    const date = ref({ from: startDate.value, to: endDate.value });
    if (game.value === "corepunk") {
      console.log("Game is Corepunk");
      date.value = { from: "2019/01/01", to: endDate.value };
      store.commit("devposts/setDateFilter", {
        min: "2019/01/01",
        max: endDate.value,
      });
    } else {
      console.log("Date is", startDate.value);
      console.log("EndDate is", endDate.value);
      store.commit("devposts/setDateFilter", {
        min: startDate.value,
        max: endDate.value,
      });
    }

    const setDateFilter = (dates) => {
      date.value = dates;
    };

    const messagePosts = computed(() => {
      store.dispatch("devposts/setIsLoadingPosts", true);
      console.log("started loading state.");
      if (userData.value[0] && userSelected.value) {
        let minDate = 0;
        let maxDate = 0;

        if (!date.value) {
          console.log("Date not exists right now");
        } else {
          if (date.value.from) {
            minDate = new Date(date.value.from).getTime();
            maxDate = new Date(date.value.to).getTime();
          } else {
            minDate = new Date(date.value).addHours(2).getTime();
            maxDate = new Date(minDate).addHours(24).getTime();
          }
        }
        const filteredArray = [];
        if (userSelected.value === "all") {
          console.log("We should show all messages");
          for (let currUser in userData.value[0]) {
            let loopUser = userData.value[0][currUser];
            let avatarURL = loopUser.avatar_url;
            if (avatarURL === "None") {
              avatarURL =
                "https://cdn.discordapp.com/attachments/10199988198887721767/10199988198887721767/unknown.png";
            }
            const postDate = new Date(loopUser.created_at).getTime();
            if (
              postDate >= minDate &&
              maxDate >= postDate &&
              loopUser.clean_message
                .toLowerCase()
                .includes(searchTerm.value.toLowerCase())
            ) {
              filteredArray.push({
                ...loopUser,
                author_id: loopUser.author_guid,
              });
            }
          }
        } else {
          for (let currUser in userData.value[0]) {
            let loopUser = userData.value[0][currUser];
            const postDate = new Date(loopUser.created_at).getTime();
            console.log("Debug postdate", postDate);
            if (
              loopUser.author_guid === userSelected.value &&
              postDate >= minDate &&
              maxDate >= postDate &&
              loopUser.clean_message
                .toLowerCase()
                .includes(searchTerm.value.toLowerCase())
            ) {
              filteredArray.push({
                ...loopUser,
                author_id: loopUser.author_guid,
              });
            }
          }
        }
        filteredArray.sort(function (a, b) {
          return new Date(b.created_at) - new Date(a.created_at);
        });
        return filteredArray;
      } else {
        return [];
      }
    });
    const site_url = computed(() => game.value + "/" + game_id.value);

    return {
      userData,
      authors,
      userSelected,
      messagePosts,
      date,
      url: site_url.value,
      setDateFilter,
      setSearchTerm,
      selectedGame: game.value,
    };
  },
};
</script>

<style scoped>
.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

main {
  display: grid;
  grid-template-columns: auto 1fr;
  height: 100vh;
  justify-content: center;
  column-gap: 5px;
  max-width: 1000px;
  margin: 50px auto;
}

@media screen and (max-width: 1000px) {
  main {
    display: block;
  }
}
</style>
