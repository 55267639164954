import "./styles/quasar.sass";
import "@quasar/extras/material-icons/material-icons.css";

// To be used on app.use(Quasar, { ... })
import Notify from 'quasar/src/plugins/Notify.js';;
export default {
  plugins: {
    Notify,
  },
  config: {
    notify: {
      /* look at QuasarConfOptions from the API card */
    },
  },
};
