import { createStore } from "vuex";
import devpostsModule from "./modules/devposts/index";

const store = createStore({
  modules: {
    devposts: devpostsModule,
  },
});

export default store;
