<template>
  <section :class="classes">
    <slot></slot>
  </section>
</template>

<script>
import { toRefs, computed } from "vue";
export default {
  props: ["border", "scrollable", "padding"],
  setup(props) {
    const { border, scrollable, padding } = toRefs(props);

    const classes = computed(() => {
      let classList = [];

      if (border.value) {
        classList.push("border");
      }
      if (scrollable.value) {
        classList.push("scroll");
      }
      if (padding.value) {
        classList.push("padding");
      }
      return classList;
    });

    return { classes };
  },
};
</script>

<style scoped>
@media screen and (max-width: 459px) {
  section {
    margin: 0rem;

    padding: 1rem;
    flex: 1;
  }
}

@media screen and (min-width: 460px) {
  section {
    margin: 0.5rem;
    flex: 1;
  }
  .padding {
    padding: 1rem;
  }
}
section::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
.scroll {
  max-height: calc(100vh - 100px);
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}
.border {
  border: 1px solid #dcddd4;
  border-radius: 5px;
}
</style>
