import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const devpostsModule = {
  namespaced: true,
  state() {
    return {
      devposts: [],
      selectedUser: null,
      selectedGame: null,
      pushToken: null,
      pushEnabled: false,
      subscribedTopics: null,
      loadingPush: false,
      isLoadingPosts: false,
      authors: {},
      lastCheckedURL: null,
      games: [],
      filterDateMin: "2023/01/01",
      filterDateMax: "2023/01/01",
    };
  },
  getters,
  mutations,
  actions,
};

export default devpostsModule;
