const dbName = "devPostsDB";
const storeName = "posts";

function openDB() {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName, 1);
    request.onerror = (event) => reject(event.target.error);
    request.onsuccess = (event) => resolve(event.target.result);
    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      db.createObjectStore(storeName, { keyPath: "game_id" });
    };
  });
}

export async function saveData(game_id, data) {
  const db = await openDB();
  const transaction = db.transaction([storeName], "readwrite");
  const store = transaction.objectStore(storeName);
  store.put({ game_id, data });
  return new Promise((resolve, reject) => {
    transaction.oncomplete = () => resolve();
    transaction.onerror = (event) => reject(event.target.error);
  });
}

export async function getData(game_id) {
  const db = await openDB();
  const transaction = db.transaction([storeName], "readonly");
  const store = transaction.objectStore(storeName);
  const request = store.get(game_id);
  return new Promise((resolve, reject) => {
    request.onsuccess = () =>
      resolve(request.result ? request.result.data : null);
    request.onerror = (event) => reject(event.target.error);
  });
}
