export default {
  setData(state, data) {
    state.devposts = [];
    state.devposts.push(data);
    console.log("current devposts");
    console.log(state.devposts);
  },
  setActiveUser(state, id) {
    state.selectedUser = id;
  },
  setSelectedGame(state, game) {
    state.selectedGame = game;
  },
  setPushToken(state, token) {
    state.pushToken = token;
  },
  setPushEnabled(state, status) {
    state.pushEnabled = status;
  },

  setSubscribedTopics(state, data) {
    state.subscribedTopics = data;
  },
  setLoadingPush(state, status) {
    state.loadingPush = status;
  },
  setIsLoadingPosts(state, status) {
    state.isLoadingPosts = status;
  },
  setAuthors(state, authors) {
    state.authors = authors;
  },
  setGames(state, games) {
    console.log("From setGames", games);
    state.games = games;
  },

  setDateFilter(state, { min, max }) {
    console.log("setting Min/max Time", min, max);
    state.filterDateMin = min;
    state.filterDateMax = max;
  },

  setLastCheckedURL(state, url) {
    state.lastCheckedURL = url;
  },
};
