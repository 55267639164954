<template>
  <header :class="headerClass">
    <div class="container">
      <span class="logo"><img id="logo" :src="logo" :alt="selectedGame + ' Logo'" /></span><span class="hiroshige">Dev-Tracker</span>
    </div>
    <nav class="self-left">
      <q-tabs v-model="tab">
        <q-tab name="home" label="home" />
        <q-tab v-if="selectedGame" name="game" :label="selectedGame" />
      </q-tabs>
    </nav>
  </header>
</template>

<script>
import router from "@/router";
import { ref, watch, computed } from "vue";
import { useStore } from "vuex";
export default {
  components: {},
  setup() {
    const store = useStore();

    const selectedGame = computed(() => store.getters["devposts/getSelectedGame"]);
    const tab = ref(selectedGame.value != null ? selectedGame.value : "home");
    watch(tab, (newTab) => {
      if (newTab === "home") {
        router.push("/");
      }
    });
    watch(selectedGame, () => {
      tab.value = "game";
    });
    const logo = computed(() => {
      if (selectedGame.value != null) {
        return "/static/images/" + selectedGame.value + ".webp";
      } else {
        return "/static/images/logo.png";
      }
    });
    const headerClass = computed(() => {
      const headerArray = [];
      if (selectedGame.value === null) {
        headerArray.push("header-clean");
        headerArray.push("navbar-clean");
        return headerArray;
      } else {
        headerArray.push(`header-${selectedGame.value}`);
        headerArray.push(`navbar-${selectedGame.value}`);
        return headerArray;
      }
    });
    return { tab, selectedGame, logo, headerClass };
  },
};
</script>

<style scoped>
@media screen and (max-width: 450px) {
  .logo {
    display: none;
  }
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.navbar-paxdei {
  display: grid;
  position: relative;
  grid-template-columns: auto 2fr;
  grid-column-gap: 5px;
  align-items: center;
}
.self-left {
  justify-self: left;
  align-self: left;
}

#logo {
  margin-top: 1em;
  max-width: 200px;
  max-height: 100px;
  margin-left: 20px;
}
.header-paxdei {
  background-color: rgb(220 221 212);
  color: rgb(34 31 25);
}
.header-corepunk {
  background-color: #b8ad93;
  color: rgb(34 31 25);
}
.header-clean {
  background-color: rgb(220 221 212);
  color: rgb(34 31 25);
}

.navbar-paxdei {
  display: grid;
  position: relative;
  grid-template-columns: auto 2fr;
  grid-column-gap: 5px;
  align-items: center;
}
.navbar-paxdei:after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  opacity: 1;
  bottom: -13px;
  height: 14px;
  background-size: 500px 100%;
  background-image: url("../static/images/scratch-paxdei.png");
  background-repeat: repeat-x;
  background-position-y: top;
  transform: rotate(180deg);
}
.navbar-corepunk {
  display: grid;
  position: relative;
  grid-template-columns: auto 2fr;
  grid-column-gap: 5px;
  align-items: center;
}
.navbar-corepunk:after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  opacity: 1;
  bottom: -13px;
  height: 14px;
  background-size: 500px 100%;
  background-image: url("../static/images/scratch-corepunk.png");
  background-repeat: repeat-x;
  background-position-y: top;
  transform: rotate(180deg);
}
.navbar-clean {
  display: grid;
  position: relative;
  grid-template-columns: auto 2fr;
  grid-column-gap: 5px;
  align-items: center;
}
.navbar-clean:after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  opacity: 1;
  bottom: -13px;
  height: 14px;
  background-size: 500px 100%;
  background-image: url("../static/images/scratch-paxdei.png");
  background-repeat: repeat-x;
  background-position-y: top;
  transform: rotate(180deg);
}
</style>
